import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../assets/scss/main.scss"

class Credits extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Marco Mazzocchi - Credits" />
        <div className="container my-5">
          <div className="row justify-content-md-center">
            <div className="col-md-8">
              <h1>Credits</h1>
              <h2>Immagini</h2>
              <ul className="list-unstyled">
                <li className="media mb-2">
                  <a
                    target="_blank"
                    href="/assets/img/hero/reza-namdari-ZgZsKFnSbEA-unsplash.jpg"
                  >
                    <img
                      src="/assets/img/hero/reza-namdari-ZgZsKFnSbEA-unsplash.jpg"
                      className="mr-3 img-fluid"
                      alt="Reza Namdari"
                      width={100}
                    />
                  </a>
                  <div className="media-body">
                    Foto di{" "}
                    <a
                      target="_blank"
                      href="https://unsplash.com/@rezanamdari?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                    >
                      Reza Namdari
                    </a>{" "}
                    su Unsplash
                  </div>
                </li>
                <li className="media">
                  <a
                    target="_blank"
                    href="/assets/img/contact/janko-ferlic-h9Iq22JJlGk-unsplash.jpg"
                  >
                    <img
                      src="/assets/img/contact/janko-ferlic-h9Iq22JJlGk-unsplash.jpg"
                      className="mr-3 img-fluid"
                      alt="Janko Ferlič"
                      width={100}
                    />
                  </a>
                  <div className="media-body">
                    Foto di{" "}
                    <a
                      target="_blank"
                      href="https://unsplash.com/@itfeelslikefilm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
                    >
                      Janko Ferlič
                    </a>{" "}
                    su Unsplash
                  </div>
                </li>
              </ul>
              <h2>Tema</h2>
              <a
                target="_blank"
                href="https://themeforest.net/item/jackdaw-multipurpose-portfolio-html5-template/25947855"
              >
                Jackdraw
              </a>{" "}
              da themeforest.net
              <h3>Codice</h3>
              <ul>
                <li>
                  <a target="_blank" href="http://getbootstrap.com">
                    Bootstrap 4
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://isotope.metafizzy.co/">
                    Isotope
                  </a>
                </li>
                <li>
                  <a target="_blank" href="http://www.jquery.com/">
                    jQuery
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://owlcarousel2.github.io/OwlCarousel2/"
                  >
                    OwlCarousel
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://github.com/nk-o/jarallax">
                    Jarallax
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://michalsnik.github.io/aos/">
                    Animate On Scroll
                  </a>
                </li>
              </ul>
              <h3>Fonts</h3>
              <ul>
                <li>
                  <a target="_blank" href="http://www.google.com/fonts">
                    Google
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://lineicons.com/">
                    LineIcons
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Credits

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
